<!-- 品牌授权列表 -->
<template>
  <div>
    <tTip>{{ tipTxt }}</tTip>
    <div class="btn-box">
      <ButtonHoc type="primary" @click="toApply">申请品牌授权</ButtonHoc>
      <ButtonHoc @click="toApplyRecord">申请记录</ButtonHoc>
    </div>
    <div>
      <el-table
        :data="list"
        style="width: 100%"
        header-align="center"
        v-loading="listLoading"
      >
        <el-table-column label="授权品牌" prop="brandName"> </el-table-column>
        <el-table-column
          prop="channelName"
          label="授权渠道"
          width="auto"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="platformShopName"
          label="授权店铺"
          width="auto"
          align="center"
        >
        </el-table-column>
        <el-table-column label="发放时间" width="auto" align="center">
          <template slot-scope="scope">
            <span class="table__name--price">{{
              scope.row.signTime | parseTime("{y}/{m}/{d}")
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="授权有效时间" width="auto" align="center">
          <template slot-scope="scope">
            <span class="table__name--price"
              >{{ scope.row.startDate | parseTime("{y}/{m}/{d}") }}-{{
                scope.row.endDate | parseTime("{y}/{m}/{d}")
              }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="statusName"
          label="授权状态"
          width="210px"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.statusName }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="210px" align="center">
          <template slot-scope="scope">
            <ButtonHoc
              type="text"
              @click="openContractUrl(scope.row.contractUrl)"
              >查看授权书</ButtonHoc
            >
            <span
              class="content-item-btn content-item-btnText"
              v-if="scope.row.renewStatus === 'RENEW'"
              type="text"
              >续签中</span
            >
            <span
              class="content-item-btn content-item-btnText"
              v-if="scope.row.renewStatus === 'FINISH'"
              type="text"
              >已申请续签</span
            >
            <ButtonHoc
              class="content-item-btn"
              type="text"
              v-if="
                (scope.row.renewStatus === 'RENEW_SOON' ||
                  scope.row.renewStatus === 'RENEW_EXPIRED') &&
                  scope.row.shopType !== 'STORELESS' &&
                  scope.row.licenseType === 'ONLINE' &&
                  scope.row.licenseScene === 'LICENSE'
              "
              @click="preResign(scope.row)"
              >续签</ButtonHoc
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          :current-page="pageNo"
          :disabled="listLoading"
          :page-size="pageSize"
          :page-sizes="[10, 20, 30, 40]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          background
          layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
      </div>
    </div>
    <YDialog ref="YDialog" closeBtn @ok="ok"></YDialog>
  </div>
</template>

<script>
import tTip from '@/components/StyleComponents/t-tip';
import { distributorLicenseListMine } from '@/api/brandauthorization';
import YDialog from '@/components/Y-Dialog';
import record from './form/record.vue';
import renew from './form/renew';
import eventReporter from '@/utils/event-reporter';
export default {
  data() {
    return {
      tipTxt:
        '品牌授权书可以用于在已经授权的渠道开设相应品牌的专营店或者专卖店，注意：授权书仅在授权时间内有效，超过效期之后，需要重新申请授权。',
      list: [],
      listLoading: false,
      pageNo: 1,
      pageSize: 10,
      total: 0
    };
  },

  components: { tTip, YDialog },

  computed: {
    postData() {
      return {};
    }
  },

  created() {
    this.fetchData();
  },

  mounted() {},

  methods: {
    ok(data) {
      if (!data) {
        return;
      }
      data.then(() => {
        this.fetchData();
      });
    },
    toApply() {
      eventReporter.trackClick(this.$route, {
        name: '点击申请按钮',
        event_source: 'submit_license_apply'
      });
      this.$emit('toApply', 'myform');
    },
    toApplyRecord() {
      eventReporter.trackClick(this.$route, {
        name: '查看授权申请记录',
        event_source: 'query_license_apply_page'
      });
      this.$refs.YDialog.open({
        component: record,
        closeBtn: false,
        width: '1000px',
        title: '申请记录'
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.fetchData();
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.fetchData();
    },
    fetchData() {
      this.listLoading = true;
      const listQuery = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        data: this.postData
      };
      distributorLicenseListMine(listQuery)
        .then(response => {
          const { list, total } = response.data;
          this.list = list;
          this.total = total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    openContractUrl(url) {
      eventReporter.trackClick(this.$route, {
        name: '查看授权书',
        event_source: 'query_license'
      });
      window.open(url);
    },
    // 续签弹窗
    async preResign(row) {
      this.$refs.YDialog.open({
        component: renew,
        width: '600px',
        title: '授权书续签',
        data: {
          ...row
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.btn-box {
  margin-bottom: 20px;
}
</style>
