<!-- 品牌授权列表 -->
<template>
  <div class="brand-list-box">
    <div class="search-box">
      <el-input v-model="brandName" placeholder="请输入品牌名称查询" clearable></el-input>
      <el-button class="search-box--btn" type="primary" @click="search">查询</el-button>
    </div>
    <el-checkbox v-model="checked" class="list-checkbox">只看可授权品牌</el-checkbox>
    <el-table
      class="brand-list-table"
      :data="list"
      style="width: 100%"
      header-align="center"
      v-loading="listLoading"
    >
      <el-table-column label="授权品牌" prop="brandName" width="auto" align="center">
        <template slot-scope="scope">
          <template v-if="isSTORELESS">
            <div class="st-box">
              <div class="brand-name-box dsno" v-for="(item, i) of scope.row.brandVOList" :key="i">
                <img class="brand-name-box--i" :src="item.logoUrl" :alt="item.brandName" />
                <div class="brand-name-box--n">{{ item.brandName }} <span v-if="item.izShowTrade==='1'">({{item.tradeTypeName}})</span> </div>
              </div>
              <template v-if="scope.row.brandVOList.length && scope.row.brandVOList[0].izShowTrade==='1'">
                <el-tooltip placement="right">
                  <div slot="content">售卖/代发一般贸易货品时，申请品牌的大贸授权书；<br/>代发保税区（跨境）商品时，申请品牌的跨境代发授权书</div>
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </template>
            </div>
          </template>
          <template v-else>
            <div class="brand-name-box">
              <img class="brand-name-box--i" :src="scope.row.logoUrl" :alt="scope.row.brandName" />
              <div class="brand-name-box--n">
                {{ scope.row.brandName }}
                <span v-if="scope.row.izShowTrade==='1'">({{scope.row.tradeTypeName}})</span>
              </div>
              <template v-if="scope.row.izShowTrade==='1'">
                <el-tooltip placement="right">
                  <div slot="content">售卖/代发一般贸易货品时，申请品牌的大贸授权书；<br/>代发保税区（跨境）商品时，申请品牌的跨境代发授权书</div>
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </template>
            </div>
          </template>
        </template>
      </el-table-column>
      <template v-if="isSTORELESS">
        <!-- 无店 -->
        <el-table-column prop="statusName" label="满足订单号" width="auto" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.isLicense === '1'">
              <div>满足授权条件</div>
              <div>
                满足条件订单：{{ scope.row.firstPurchaseOrderNo }}
                <router-link v-if="scope.row.firstOrderType==='SELF_OPERATED_ORDER'"
                  :to="'/syoung/order/inquiry/detail/' + scope.row.firstPurchaseOrderId"
                  class="link linknone"
                  ><el-button type="text">订单详情</el-button></router-link>
              </div>
            </div>
            <div v-else class="col1">暂无订单满足</div>
          </template>
        </el-table-column>
        <!-- 无店 end-->
      </template>
      <template v-else>
        <!-- 有店 -->
        <el-table-column label="达成条件" width="auto" align="left">
          <template slot-scope="scope">
            <div class="page-content-grade">
              <!-- <div class="page-content-gradeTitle">授权门槛</div> -->
              <div class="page-content-gradeItem" v-if="scope.row.firstPurchaseAmount">
                <i
                  class="purchase"
                  :class="scope.row.isFirstPurchase === '1' ? 'el-icon-success' : 'el-icon-error'"
                ></i>
                <span class="page-content-gradeText"
                  >首采门槛{{ scope.row.firstPurchaseAmount }}元（单笔订单）</span
                >
              </div>
              <div class="page-content-gradeItem" v-if="scope.row.depositAmount">
                <i
                  class="purchase"
                  :class="scope.row.isDeposit === '1' ? 'el-icon-success' : 'el-icon-error'"
                ></i>
                <span class="page-content-gradeText"
                  >缴纳保证金{{ scope.row.depositAmount }}元</span
                >
              </div>
              <div class="page-content-gradeItem" v-if="scope.row.totalPurchaseAmount">
                <i
                  class="purchase"
                  :class="scope.row.isTotalPurchase === '1' ? 'el-icon-success' : 'el-icon-error'"
                ></i>
                <span class="page-content-gradeText"
                  >累计采货{{ scope.row.totalPurchaseAmount }}元</span
                >
              </div>
              <template v-if="scope.row.commodityThresholdList && scope.row.commodityThresholdList.length">
                <div v-for="(item, index) in scope.row.commodityThresholdList" :key="index" class="page-content-gradeItem">
                  <i class="purchase" :class="item.isThreshold === '1' ? 'el-icon-success' : 'el-icon-error'"></i>
                  <span>{{ item.commodityName }}累计采货{{ item.amount }}元</span>
                </div>
              </template>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="授权渠道" width="auto" align="left">
          <template slot-scope="scope">
            <img
              class="channel-img"
              v-for="(item, i) of scope.row.channelList"
              :src="item"
              :key="i"
            />
          </template>
        </el-table-column>
        <!-- 有店 end-->
      </template>
      <el-table-column label="操作" width="210px" align="left">
        <template slot-scope="scope">
          <div class="list-btn-box">
            <template v-if="scope.row.isLicense === '1'">
              <!-- 达成 -->
              <ButtonHoc key="od" v-if="isSTORELESS" @click="toOrder(scope.row)"
                >选择订单</ButtonHoc
              >
              <ButtonHoc key="ds" type="primary" @click="toApply(scope.row)">申请授权</ButtonHoc>
              <ButtonHoc key="qk" v-if="isSTORELESS" @click="toView(scope.row)">达成情况</ButtonHoc>
            </template>
            <template v-else>
              <!-- 未达成 -->
              <ButtonHoc key="gz" v-if="isSTORELESS" @click="toViewRules(scope.row)"
                >授权规则</ButtonHoc
              >
              <ButtonHoc key="dc" v-else @click="toView(scope.row)">达成情况</ButtonHoc>
            </template>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <YDialog width="600px" ref="YDialog" @ok="onYDialogTap"></YDialog>
  </div>
</template>

<script>
import { brandLicenseListMyPage, storelessLicenseListMyLicense } from '@/api/brandauthorization';
import YDialog from '@/components/Y-Dialog';
import brandMyDetail from './shop/brand-my-detail.vue'; // 我的达成情况 有店
import brandMyDetailNoShop from './noshop/brand-my-detail-noshop'; // 我的达成情况 无店
import chooseOrderList from './choose-order-list'; // 选择订单

export default {
  data() {
    return {
      brandName: '', // 品牌名称
      checked: false,
      pageNo: 1,
      pageSize: 1000,
      total: 0,
      list: [],
      listLoading: false,
      formLabelAlign: {}, // 选中的传给外界的数据
    };
  },
  inject: ['getInitData'],
  components: { YDialog },
  watch: {
    checked() {
      this.fetchData();
    },
  },
  props: {
    toNext: {
      // 下一步
      type: Function,
    },
  },
  computed: {
    isLicense() {
      // 是否可授权 0-不可以授权,1-可以授权
      return this.checked ? '1' : '0';
    },
    postData() {
      const { shopTypeValue, channelValue } = this.getInitData();
      return {
        brandName: this.brandName,
        channel: channelValue,
        shopType: shopTypeValue,
        isLicense: this.isLicense,
      };
    },
    // 是否是无店,其他是有店
    isSTORELESS() {
      const { shopTypeValue } = this.getInitData();
      return shopTypeValue === 'STORELESS';
    },
  },

  created() {
    this.fetchData();
  },

  mounted() {},

  methods: {
    search() {
      this.pageNo = 1;
      this.fetchData();
    },
    fetchData() {
      this.listLoading = true;
      const listQuery = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        data: this.postData,
      };
      const req = this.isSTORELESS ? storelessLicenseListMyLicense : brandLicenseListMyPage;
      req(listQuery)
        .then((response) => {
          const { list, total } = response.data;
          if (!this.isSTORELESS) {
            // 有店
            list.forEach((item) => {
              item.channelList = item.channels.map((channel) => {
                // console.log('channel', channel);
                return `https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/mp/brand-authorization/channel-change/${channel}-selected.png`;
              });
            });
          }
          this.list = !this.checked ? list : list.filter((i) => i.isLicense === '1');
          this.total = total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    // 申请授权 下一步
    toApply(row) {
      const { otherValue = '' } = this.getInitData();
      const params = row;
      if(otherValue){
        params.otherValue = otherValue;
      }
      this.formLabelAlign = params;
      this.toNext();
    },
    // 外部调用 把选中的数据抛出去
    next(callback) {
      callback({ current: this.formLabelAlign });
    },
    // 达成情况
    toView(row) {
      //  console.log(row);

      const { shopTypeValue, channelValue } = this.getInitData();
      this.$refs.YDialog.open({
        model: 'hide-top',
        component: this.isSTORELESS ? brandMyDetailNoShop : brandMyDetail,
        data: {
          // 初始参数值
          ...row,
          channel: channelValue,
          shopType: shopTypeValue,
        },
      });
    },
    // 查看规则
    toViewRules(row) {
      this.$refs.YDialog.open({
        model: 'hide-top',
        component: brandMyDetailNoShop,
        data: {
          // 初始参数值
          ...row,
          cpType: 'rules',
        },
      });
    },
    // 选择订单
    toOrder(row) {
      const { channelValue } = this.getInitData();
      this.$refs.YDialog.open({
        model: 'hide-top',
        component: chooseOrderList,
        width: '600px',
        data: {
          // 初始参数值
          ...row,
          channel: channelValue,
        },
      });
    },
    // 弹出框确定时需要存的值
    onYDialogTap(e) {
      // console.log('onYDialogTap', e);
      this.list = this.list.map((item) => {
        if (item.id === e.id) {
          const { orderNo, orderId } = e;
          return {
            ...item,
            firstPurchaseOrderNo: orderNo,
            firstPurchaseOrderId: orderId,
          };
        } else {
          return item;
        }
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.col1 {
  color: #AB0033;;
}
.brand-list-box {
  padding-bottom: 50px;
}
.search-box {
  display: flex;
  align-items: center;
  padding: 0 36px;
  margin-top: 30px;
  &--btn {
    margin-left: 24px;
  }
  .el-input {
    width: 217px;
  }
}
.list-checkbox {
  padding: 0 36px;
  margin: 20px 0;
}
.pagination {
  margin-bottom: 37px;
}
.brand-list-table {
  .el-icon-question{
    font-size: 16px;
    color:#C0C4CC;
    margin-left: 12px;
  }
  .st-box {
    display: flex;
    align-items: center;
    padding-left: 50px;
  }
  .brand-name-box {
    display: flex;
    align-items: center;
    margin-right: 10px;
    &.dsno {
      flex-direction: column;
      justify-content: center;
      .brand-name-box--n {
        margin-left: 0;
      }
    }
    &--i {
      width: 55px;
      height: 55px;
    }
    &--n {
      margin-left: 20px;
      font-size: 14px;
      span{
        font-size: 12px;
      }
    }
  }
  .channel-img {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }
}
.page-content-gradeItem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.purchase {
  font-size: 20px;
  margin-right: 7px;
  &.el-icon-success {
    color: #AB0033;;
  }
  &.el-icon-error {
    color: #999999;
  }
}
.list-btn-box {
  /deep/ {
    .el-button {
      display: block;
      margin: 10px 0;
    }
    .el-button + .el-button {
      margin-left: 0;
    }
  }
}
</style>
